

import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { throwError, Subject, BehaviorSubject, Observable } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class StorageValuesService {
  private atHome = new BehaviorSubject(null);
  private atRibbon = new BehaviorSubject<boolean>(false);


  constructor() {}

  setAtHome(atHome: boolean) {
    this.atHome.next(atHome);
  }

  getAtHome() {
    return this.atHome;
  }

  setAtRibbon(atRibbon: boolean) {
    this.atRibbon.next(atRibbon);
  }

  getAtRibbon() {
    return this.atRibbon;
  }

  getValue(): Observable<boolean> {
    return this.atRibbon.asObservable();
  }
  setValue(newValue): void {
    this.atRibbon.next(newValue);
  }

}
