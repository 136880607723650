import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TechnologiesComponent } from './technologies.component';
import { NavigationModule } from 'src/app/components/navigation/navigation.module';
import { StickRibbonModule } from 'src/app/components/stick-ribbon/stick-ribbon.module';
import { TechnologiesContentModule } from 'src/app/components/technologies/technologies.module';

@NgModule({
  declarations: [TechnologiesComponent],
  imports: [
    CommonModule,
    NavigationModule,
    StickRibbonModule,
    TechnologiesContentModule
  ]
})
export class TechnologiesModule { }
