import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AboutUsModule } from "./pages/about-us/about-us.module";
import { WINDOW_PROVIDERS } from "./services/window.service";
import { MatDialogModule } from "@angular/material/dialog";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ServicesModule } from './pages/services/services.module';
import { NavigationModule } from './components/navigation/navigation.module';
import { FooterModule } from './components/footer/footer.module';
import { TechnologiesModule } from './pages/technologies/technologies.module';
import { CareersModule } from './pages/careers/careers.module';
import { ContactModule } from './pages/contact/contact.module';
import { HomeModule } from './pages/home/home.module';
import { CountdownDirective } from './directives/countdown.directive';
import { GeneralModalModule } from './components/general-modal/general-modal.module';
import { WhatsappModule } from "./components/whatsapp/whatsapp.module";


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    NavigationModule,
    FooterModule,
    AboutUsModule,
    ServicesModule,
    TechnologiesModule,
    CareersModule,
    ContactModule,
    HomeModule,
    GeneralModalModule,
    WhatsappModule
  ],
  providers: [WINDOW_PROVIDERS],
  bootstrap: [AppComponent]
})
export class AppModule { }
