import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StickRibbonComponent } from './stick-ribbon.component';



@NgModule({
  declarations: [StickRibbonComponent],
  exports: [StickRibbonComponent],
  imports: [
    CommonModule
  ]
})
export class StickRibbonModule { }
