

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionService } from 'src/app/services/connection.service';

declare const Waypoint: any;


@Component({
  selector: 'app-services-content',
  templateUrl: './services-content.component.html',
  styleUrls: ['./services-content.component.scss']
})
export class ServicesContentComponent implements OnInit {
  servicesTitle: boolean = false;
  additionalTitle: boolean = false;
  servicesItems: any = [];

  otherServices: any = [];


  constructor(private connectionService: ConnectionService) { }

  ngOnInit() {
    let self = this;
    let waypoint = new Waypoint({

      element: document.getElementById('servicesTitle'),
      handler: function (direction) {
        self.servicesTitle = true;
      },
      offset: '65%'
    });

    let waypoint2 = new Waypoint({

      element: document.getElementById('additionalTitle'),
      handler: function (direction) {
        self.additionalTitle = true;
      },
      offset: '55%'
    });

    this.loadContent();
    // this.loadFake();
  }

  loadFake() {
    this.servicesItems = [
      { "id": "1", "name": "Software a la medida", "description": "Nos enfocamos en las necesidades específicas del cliente, adaptándonos a los procesos de gestión.", "icon": "icon-embed2" },
      { "id": "6", "name": "Outsourcing de personal", "description": "Nos acoplamos a la forma de trabajo colaborativo, adaptándonos a cualquier cambio con mayor rapidez.", "icon": "icon-users" },
      { "id": "7", "name": "Fábrica de Software", "description": "Apoyar al clientes a realizar puntualmente actividades del ciclo de desarrollo de software apegándose a características y requerimientos solicitados.", "icon": "icon-cog" }
    ];
    this.otherServices = [
      { "id": "1", "name": "Consultoría de TI" },
      { "id": "2", "name": "Integración de Servicios" },
      { "id": "3", "name": "Soporte a Aplicaciones" },
      { "id": "4", "name": "Administración de Proyectos" },
      { "id": "5", "name": "Mantenimiento de Sistemas" },
      { "id": "6", "name": "Capacitación de personal" }
    ];
  }

  loadContent() {
    let obj = {
      table: "services"
    };
    

    this.connectionService
      .getService("getItem.php", obj)
      .subscribe((data) => {
        

        if (!data.error) {
          this.servicesItems = data.info;
          this.loadOtherServices();
        }
      });
  }

  loadOtherServices() {
    let obj = {
      table: "additionalServices"
    };
    

    this.connectionService
      .getService("getItem.php", obj)
      .subscribe((data) => {

        if (!data.error) {
          this.otherServices = data.info;
        }
      });
  }

}
