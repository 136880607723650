import { Directive, Input, OnInit, Renderer2, ElementRef } from "@angular/core";
import { StorageValuesService } from '../services/storage-values.service';

@Directive({
  selector: "[countdown]",
})
export class CountdownDirective implements OnInit {
  @Input() timeSet: any;
  @Input() seconds: any;
  ready: any = false;
  TIME_LIMIT = 20;
  timerInterval: any = null;
  timePassed: any = 0;

  
  constructor(private storageValuesService: StorageValuesService, private elem: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    
    this.storageValuesService.getValue().subscribe((value) => {
      
      if(value && !this.ready){
        this.ready = true;
        this.startTimer(this.timeSet, this.seconds);
      }
    });
    
  }

  startTimer(timeSet, seconds) {
    
    this.timerInterval = setInterval(() => {
      this.timePassed = this.timePassed += 1;
      this.elem.nativeElement.innerHTML = this.timePassed + " +";

      if (this.timePassed == this.timeSet) {
        this.onTimesUp();
      }
    }, seconds);
  }

  onTimesUp() {
    clearInterval(this.timerInterval);
  }
}
