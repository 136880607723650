import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CareersContentComponent } from './careers-content.component';
import { CareersFormComponent } from './careers-form/careers-form.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import {
  MatProgressSpinnerModule,
} from "@angular/material";
@NgModule({
  declarations: [CareersContentComponent, CareersFormComponent],
  exports: [CareersContentComponent, CareersFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule
  ],
  entryComponents: [CareersFormComponent]
})
export class CareersContentModule { }
