import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss']
})
export class GeneralModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<GeneralModalComponent>,) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
