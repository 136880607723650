import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TechnologiesComponent } from "./technologies.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { StickRibbonModule } from '../stick-ribbon/stick-ribbon.module';


@NgModule({
  declarations: [TechnologiesComponent],
  exports: [TechnologiesComponent],
  imports: [CommonModule, MatExpansionModule, StickRibbonModule,],
})
export class TechnologiesContentModule {}
