import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConnectionService } from 'src/app/services/connection.service';
import { MatDialog } from '@angular/material';
import { GeneralModalComponent } from '../../general-modal/general-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  contactData: any = [];
  userForm:FormGroup;
  submitted: boolean;
  loading: boolean = false;
  successSubmition: boolean = true;
  
  error: {};
  emailPattern = "^[a-z0-9._%+-]{2,}@[a-z0-9.-]{2,}\.[a-z]{2,}$";
  phonePattern = "^[0-9._+()-]{8,16}$";
  notSame = "^(([a-zA-ZáéíóúñÁÉÍÓÚÑ¿?¡!-.@$#&()/ ])\\2{0,1}(?!\\2))*$";
  notSameWN = "^(([0-9a-zA-ZáéíóúñÁÉÍÓÚÑ¿?¡!-.@$#&() ])\\2{0,1}(?!\\2))*$";

  communication: any = [
    
    {
      img: "icon-phone",
      title: "Teléfono",
      link:"tel:+528147802232",
      description: "+52 (81) 4780 2232"
    },
    {
      img: "icon-email",
      title: "Email",
      link:"mailto:contacto@dotech.com.mx",
      description: "contacto@dotech.com.mx"
    },
   
   
    {
      img: "icon-facebook",
      title: "Facebook",
      link:"https://www.facebook.com/dotech.mx",
      description: "/dotech.mx"
    },
    {
      img: "icon-instagram",
      title: "Instagram",
      link:"https://www.instagram.com/mxdotech/",
      description: "/mxdotech"
    },
    {
      img: "icon-linkedin2",
      title: "LinkedIn",
      link:"https://www.linkedin.com/company/dotech/",
      description: "/dotech"
    }
  ];

  constructor(private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private connectionService: ConnectionService) {}

  ngOnInit() {

    this.userForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.pattern(this.notSame)]],
      userCompany: ['', [Validators.required, Validators.pattern(this.notSameWN)]],
      userEmail: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      userPhone: ['', [Validators.required, Validators.pattern(this.phonePattern)]],
      userMessage: ['', [Validators.required, Validators.pattern(this.notSameWN)]]
    });

  }

  get f() { return this.userForm.controls; }

  onSubmit() {
    this.submitted = true;
    

    // TODO: Use EventEmitter with form value
    console.log(this.userForm.value);

    if (this.userForm.invalid) {
      return;
    }else{
    
      this.loading = true;
      return this.connectionService.insertService(this.userForm.value, 'contact.php').subscribe(
        (data) => {
          if(!data.error){
            this.loading = false;
            this.successSubmition = true;
            const dialogRef = this.dialog.open(GeneralModalComponent, {
              width: '500px',
            });

            dialogRef.afterClosed().subscribe(result => {
              window.location.reload();
      
            });
            
          }else{
            this.loading = false;
            this.successSubmition = false;
            
          }
        }
      );
      
    }
  }

}
