import { Component, OnInit } from '@angular/core';
import { ConnectionService } from 'src/app/services/connection.service';
declare const Waypoint: any;

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  clientsSection: boolean = false;
  clients: any = [];
  constructor(private connectionService: ConnectionService) { }

  ngOnInit() {
    let self = this;
    let waypoint = new Waypoint({

      element: document.getElementById('clientsSection'),
      handler: function (direction) {
        self.clientsSection = true;
      },
      offset: '65%'
    });

    this.loadContent();
    // this.loadFake();
  }

  loadFake() {
    this.clients = [{
      "id": "1",
      "name": "OXXO",
      "imageUrl": "http://www.dotechmx.com/assets/images/clients/oxxo.png"
    },
    {
      "id": "2",
      "name": "ARH",
      "imageUrl": "http://www.dotechmx.com/assets/images/clients/arh.png"
    },
    {
      "id": "3",
      "name": "MAS",
      "imageUrl": "http://www.dotechmx.com/assets/images/clients/mas.png"
    },
    {
      "id": "4",
      "name": "EATON",
      "imageUrl": "http://www.dotechmx.com/assets/images/clients/eaton.png"
    },
    {
      "id": "5",
      "name": "PETRO 7",
      "imageUrl": "http://www.dotechmx.com/assets/images/clients/petro.png"
    },
    {
      "id": "6",
      "name": "FARMACIAS DEL AHORRO",
      "imageUrl": "http://www.dotechmx.com/assets/images/clients/farmacias.png"
    },
    {
      "id": "7",
      "name": "FULTRA",
      "imageUrl": "http://www.dotechmx.com/assets/images/clients/fultra.png"
    },
    {
      "id": "8",
      "name": "7 ELEVEN",
      "imageUrl": "http://www.dotechmx.com/assets/images/clients/seven.png"
    },
    {
      "id": "9",
      "name": "DALTILE",
      "imageUrl": "http://www.dotechmx.com/assets/images/clients/daltile.png"
    },
    {
      "id": "10",
      "name": "GUNTNER",
      "imageUrl": "http://www.dotechmx.com/assets/images/clients/gunter.png"
    },
    {
      "id": "11",
      "name": "GRUPO PROMAX",
      "imageUrl": "http://www.dotechmx.com/assets/images/clients/promax.png"
    },
    {
      "id": "12",
      "name": "DE ACERO",
      "imageUrl": "http://www.dotechmx.com/assets/images/clients/deacero.png"
    }];

  }

  loadContent() {

    let obj = {
      table: "clients"
    };
    

    this.connectionService
      .getService("getItem.php", obj)
      .subscribe((data) => {

        if (!data.error) {
          this.clients = data.info;
        }
      });
  }

}
