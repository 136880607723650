import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MethodologyComponent } from './methodology.component';

@NgModule({
  declarations: [MethodologyComponent],
  exports: [MethodologyComponent],
  imports: [
    CommonModule
  ]
})
export class MethodologyModule { }
