import { Component, OnInit, Input } from "@angular/core";
declare const Waypoint: any;

@Component({
  selector: "app-stick-ribbon",
  templateUrl: "./stick-ribbon.component.html",
  styleUrls: ["./stick-ribbon.component.scss"],
})
export class StickRibbonComponent implements OnInit {
  @Input() ribbonColor: any;
  @Input() ribbonText: any;
  @Input() ribbonLink: any;
  @Input() ribbonBtn: any;
  ribbonBtnColor: any = "red";
  onRibbon: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (this.ribbonColor == "red") this.ribbonBtnColor = "white";

    let self = this;
    let waypoint = new Waypoint({
      element: document.getElementById("onRibbon"),
      handler: function (direction) {
        self.onRibbon = true;
      },
      offset: "55%",
    });
  }
}
