import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { NavigationModule } from 'src/app/components/navigation/navigation.module';
import { RedRibbonModule } from 'src/app/components/red-ribbon/red-ribbon.module';
import { ClientsModule } from 'src/app/components/clients/clients.module';
import { HomeContentModule } from 'src/app/components/home-content/home-content.module';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    ClientsModule,
    RedRibbonModule,
    NavigationModule,
    HomeContentModule
  ]
})
export class HomeModule { }
