import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  ServerUrl = location.origin + '/backend/services/';
  errorData: {};

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  constructor(private http: HttpClient) { }

  insertService(formdata: any, url:any) {
    return this.http.post<any>(this.ServerUrl + url, formdata, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getService(url:any, obj:any) {
    return this.http.post<any>(this.ServerUrl + url, obj, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }



  // getService(url:any, obj:any) {
  //   return this.http.post<any>(this.ServerUrl + url,  obj, this.httpOptions).pipe(
  //     catchError(this.handleError)
  //   );
  // }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong.

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }
}
