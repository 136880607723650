import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ServicesComponent } from './pages/services/services.component';
import { TechnologiesComponent } from './pages/technologies/technologies.component';
import { CareersComponent } from './pages/careers/careers.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';


export const routes: Routes = [
  {
    path: "Inicio",
    component: HomeComponent
  },

  {
    path: "Tecnologias",
    component: TechnologiesComponent
  },
  {
    path: "Servicios",
    component: ServicesComponent
  },
  {
    path: "Contacto",
    component: ContactComponent
  },
  {
    path: "Careers",
    component: CareersComponent
  },
  {
    path: "Nosotros",
    component: AboutUsComponent
  },
  {
    path: "",
    redirectTo: "/Inicio",
    pathMatch: "full"
  },
  {
    path: "**",
    redirectTo: "/Inicio",
    pathMatch: "full"
  }
];

const routerOptions: ExtraOptions = {
  anchorScrolling: "enabled",
  scrollPositionRestoration: "enabled"
};

@NgModule({
  imports: [RouterModule, RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
