import { Component, OnInit } from '@angular/core';
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { ConnectionService } from 'src/app/services/connection.service';
declare const Waypoint: any;

@Component({
  selector: 'app-red-ribbon',
  templateUrl: './red-ribbon.component.html',
  styleUrls: ['./red-ribbon.component.scss']
})
export class RedRibbonComponent implements OnInit {

  onRibbon: boolean=false;
  ribbonData: any = [];

  constructor(private storageValuesService: StorageValuesService,
    private connectionService: ConnectionService) { }

  ngOnInit(): void {
    let self = this;
    let waypoint = new Waypoint({
      
      element: document.getElementById('ribbonSection'),
      handler: function(direction) {        
        self.storageValuesService.setValue(true);
      },
      offset: '65%'
    });

    this.loadContent();
    // this.loadFake();
  }

  loadFake(){
    
    this.ribbonData = [
      {"id":"1","years":"9","yearsDesc":"Años de Experiencia","devs":"120", "devsDesc":"Colaboradores"}
      ];
    this.ribbonData = this.ribbonData[0];
    
  }



  loadContent(){
    
    let obj = {
      idPosition: '1',
      table: "ribbonData"
    };
    this.connectionService.getService("getItemData.php", obj).subscribe(data => {
      
      if (!data.error) {
        this.ribbonData = data.info[0];
      }
    });
  }


}
