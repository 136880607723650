import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralModalComponent } from './general-modal.component';

@NgModule({
  declarations: [GeneralModalComponent],
  exports: [GeneralModalComponent],
  imports: [
    CommonModule
  ],
  entryComponents: [GeneralModalComponent]
})
export class GeneralModalModule { }
