

import { Component, OnInit, Inject, Input, HostBinding } from "@angular/core";
import { HostListener } from "@angular/core";
import { WINDOW } from "src/app/services/window.service";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  public navIsFixed: boolean = false;
  public mobileNav: boolean = false;
  collapsed: boolean = true;
  @Input() atHome : boolean ;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window,
  ) {}

  ngOnInit() {
    
    if(!this.atHome){
      this.navIsFixed = true;
    }
  }

  toggleNav(){
    this.collapsed= !this.collapsed;
  }


  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number =
      this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    if(this.atHome){
      if (number > 100) {
        this.navIsFixed = true;
      } else if (this.navIsFixed && number < 10) {
        this.navIsFixed = false;
      }
    }

    if (number > 60) {
      this.mobileNav = true;
    } else if (this.mobileNav && number < 10) {
      this.mobileNav = false;
    }
  }
}

