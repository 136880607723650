import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RedRibbonComponent } from './red-ribbon.component';
import { CountdownDirective } from 'src/app/directives/countdown.directive';




@NgModule({
  declarations: [RedRibbonComponent, CountdownDirective],
  exports: [RedRibbonComponent],
  imports: [
    CommonModule
  ]
})
export class RedRibbonModule { }
