import { Component, OnInit } from '@angular/core';
import { ConnectionService } from 'src/app/services/connection.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  icons: any = [
    
    {
      img: "icon-phone",
      title: "Teléfono",
      link:"tel:+528147802232",
      description: "+52 (81) 4780 2232"
    },
    {
      img: "icon-whatsapp",
      title: "Whatsapp",
      link:"https://api.whatsapp.com/send?phone=528147802232",
      description: "+52 (81) 4780 2232"
    },
    {
      img: "icon-email",
      title: "Email",
      link:"mailto:contacto@dotech.com.mx",
      description: "contacto@dotech.com.mx"
    },
    {
      img: "icon-facebook",
      title: "Facebook",
      link:"https://www.facebook.com/dotech.mx",
      description: "/dotech.mx"
    },
    {
      img: "icon-instagram",
      title: "Instagram",
      link:"https://www.instagram.com/mxdotech/",
      description: "/mxdotech"
    },
    {
      img: "icon-linkedin2",
      title: "LinkedIn",
      link:"https://www.linkedin.com/company/dotech/",
      description: "/dotech"
    }
  ];

  privacyItem: any = [];

  constructor(private connectionService: ConnectionService) { }

  ngOnInit(): void {
    this.loadContent();
  }

  loadContent() {
    let obj = {
      table: "termsPrivacy"
    };

    this.connectionService
      .getService("getItem.php", obj)
      .subscribe((data) => {

        if (!data.error) {
          this.privacyItem = data.info[0];
        }
      });
  }

}
