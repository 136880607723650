


import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CareersFormComponent } from "./careers-form/careers-form.component";
import { ConnectionService } from "src/app/services/connection.service";
declare const Waypoint: any;


@Component({
  selector: 'app-careers-content',
  templateUrl: './careers-content.component.html',
  styleUrls: ['./careers-content.component.scss']
})
export class CareersContentComponent implements OnInit {
  noRows: boolean = false;
  careersTitle: boolean = false;
  careers: any = [];


  careersBanner: any = [
    {
      id: "1",
      title: "Crecimiento",
      subtitle: "profesionalmente",
    },
    {
      id: "3",
      title: "Proyectos",
      subtitle: "retadores",
    },
    {
      id: "4",
      title: "Eventos",
      subtitle: "exclusivos",
    },
    {
      id: "2",
      title: "Excelente ambiente",
      subtitle: "de trabajo",
    },
  ];

  constructor(
    public dialog: MatDialog,
    private connectionService: ConnectionService
  ) {}

  ngOnInit() {
    
    let self = this;
    let waypoint = new Waypoint({
      element: document.getElementById("careersTitle"),
      handler: function (direction) {
        self.careersTitle = true;
      },
      offset: "65%",
    });

    // this.loadFake();
    this.loadContent();
  }

  loadFake(){
    this.careers = [
      {"id":"6","name":"Frontend Developer","description":"Desarrollador forntend con experiencia en React.","idLocation":"1","locationCity":"MONTERREY, NUEVO LEÓN"},
      {"id":"7","name":"Backend Developer","description":"Desarrollador Backend con experiencia en Node","idLocation":"2","locationCity":"TEPIC, NAYARIT"},
      {"id":"9","name":"Project Manager Sr.","description":"Líder de equipo de desarrollo","idLocation":"1","locationCity":"MONTERREY, NUEVO LEÓN"},
      {"id":"21","name":"Backend Developer JR","description":"TECNOLOGÍAS C#","idLocation":"2","locationCity":"TEPIC, NAYARIT"}
    ];
  }

  loadContent() {
    let obj = {
      table: "positions"
    };

    

    this.connectionService
      .getService("getPositions.php", obj)
      .subscribe((data) => {
        

        if (!data.error) {
          this.careers = data.info;

          this.noRows = false;
        } else {
          this.noRows = true;
        }
      });
  }

  openResume(position) {
    const dialogRef = this.dialog.open(CareersFormComponent, {
      
      disableClose: true,
      data: position,
      panelClass: 'general-modal',
   
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog result:", result);
    });
  }
}
