import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesComponent } from './services.component';
import { ServicesContentModule } from 'src/app/components/services-content/services-content.module';
import { NavigationModule } from 'src/app/components/navigation/navigation.module';
import { MethodologyModule } from 'src/app/components/methodology/methodology.module';
import { StickRibbonModule } from 'src/app/components/stick-ribbon/stick-ribbon.module';

@NgModule({
  declarations: [ServicesComponent],
  exports: [ServicesComponent],
  imports: [
    CommonModule,
    ServicesContentModule,
    NavigationModule,
    MethodologyModule,
    StickRibbonModule
    
  ]
})
export class ServicesModule { }
