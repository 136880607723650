import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CvUploadService } from "src/app/services/cv-upload.service";
import { ConnectionService } from 'src/app/services/connection.service';

@Component({
  selector: "app-careers-form",
  templateUrl: "./careers-form.component.html",
  styleUrls: ["./careers-form.component.scss"],
})
export class CareersFormComponent implements OnInit {
  userForm: FormGroup;
  successSubmition: boolean = false;
  submitted: boolean;
  loading: boolean = false;
  selectedPosition: string;
  uploadResponse;
  errorSubmition: boolean = false;
  emailPattern = "^[a-z0-9._%+-]{2,}@[a-z0-9.-]{2,}\.[a-z]{2,}$";
  phonePattern = "^[0-9._+()-]{8,16}$";
  notSame = "^(([a-zA-ZáéíóúñÁÉÍÓÚÑ¿?¡!-.@$#&()/ ])\\2{0,1}(?!\\2))*$";
  notSameWN = "^(([0-9a-zA-ZáéíóúñÁÉÍÓÚÑ¿?¡!-.@$#&() ])\\2{0,1}(?!\\2))*$";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CareersFormComponent>,
    private formBuilder: FormBuilder,
    private connectionService: ConnectionService,
    private cvUpload: CvUploadService
  ) {}

  ngOnInit(): void {
    console.log("dataaa recieved is", this.data);
    
    this.userForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.pattern(this.notSame)]],
      userEmail: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      userUpload: ["", Validators.required],
      userCVLink: [""],
      userPhone: ['', [Validators.required, Validators.pattern(this.phonePattern)]],
      userMessage: ['', [Validators.pattern(this.notSameWN)]],
      userPosition: [this.data],
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.userForm.get("userUpload").setValue(file);
    }
  }

  get f() {
    return this.userForm.controls;
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;

    // TODO: Use EventEmitter with form value
    console.log(this.userForm.value);

    if (this.userForm.invalid) {
      console.log("still invalid");
      return;
    } else {
      console.log("ok lets go!");
      this.loading = true;

      //first upload CV
      const formData = new FormData();
      formData.append("userUpload", this.userForm.get("userUpload").value);

      console.log("formData", formData);

      return this.cvUpload.uploadFile(formData).subscribe(
        (res) => {
          this.uploadResponse = res;
          console.log("result is", res);

          //If Cv was uploaded, then send information about candidate
          if (!res.error) {
            this.userForm.get("userCVLink").setValue(res.url);
            console.log("FORM TO GO", this.userForm.value);

            this.connectionService
              .insertService(this.userForm.value, "cv-contact.php")
              .subscribe((data) => {
                if (!data.error) {
                  this.userForm.reset();
                  this.loading = false;
                  this.successSubmition = true;
                  this.errorSubmition=false;
                }else{
                  this.loading = false;
                  this.successSubmition = false;
                  this.errorSubmition=true;
                }
              });
          }else{
            this.loading = false;
            this.successSubmition = false;
            this.errorSubmition=true;
          }

        },
        (err) => {
          this.loading = false;
          this.successSubmition = false;
            this.errorSubmition=true;
          console.log("error is", err);
        }
      );

     
    }
  }
}
