import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesContentComponent } from './services-content.component';

@NgModule({
  declarations: [ServicesContentComponent],
  exports: [ServicesContentComponent],
  imports: [
    CommonModule
  ]
})
export class ServicesContentModule { }
