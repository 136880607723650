import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactContentComponent } from './contact-content.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import {
  MatProgressSpinnerModule,
} from "@angular/material";
import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [ContactContentComponent, ContactFormComponent],
  exports: [ContactContentComponent, ContactFormComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatProgressSpinnerModule
  ]
})
export class ContactContentModule { }
