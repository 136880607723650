
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StorageValuesService } from 'src/app/services/storage-values.service';
import { ConnectionService } from 'src/app/services/connection.service';
declare const Waypoint: any;


@Component({
  selector: 'app-home-content',
  templateUrl: './home-content.component.html',
  styleUrls: ['./home-content.component.scss']
})
export class HomeContentComponent implements OnInit {
  homeHeader1: boolean = false;
  homeSpan: boolean = false;
  highlightData: any =[];
  servicesItems:any=[];

  @Output() atHome = new EventEmitter();

  
  constructor(
    private storeService: StorageValuesService,
    private connectionService: ConnectionService
  ) { }

  ngOnInit() {
    let self = this;
    let waypoint = new Waypoint({
      
      element: document.getElementById('homeHeader1'),
      handler: function(direction) {
        self.homeHeader1 = true;
      },
      offset: '65%'
    });

    let waypoint2 = new Waypoint({
      
      element: document.getElementById('homeSpan'),
      handler: function(direction) {
        self.homeSpan = true;
      },
      offset: '65%'
    });

    this.loadContent();
    // this.loadFake();

  }


  loadFake() {
    this.servicesItems = [
      { "id": "1", "name": "Software a la medida", "description": "Nos enfocamos en las necesidades específicas del cliente, adaptándonos a los procesos de gestión.", "icon": "icon-embed2" },
      { "id": "6", "name": "Outsourcing de personal", "description": "Nos acoplamos a la forma de trabajo colaborativo, adaptándonos a cualquier cambio con mayor rapidez.", "icon": "icon-users" },
      { "id": "7", "name": "Fábrica de Software", "description": "Apoyar al clientes a realizar puntualmente actividades del ciclo de desarrollo de software apegándose a características y requerimientos solicitados.", "icon": "icon-cog" }
    ];
    this.highlightData = [
      {"id":"1","title":"AGREGAMOS VALOR A TU NEGOCIO","description":"Constante actualización de las herramientas que usamos para brindarte mejor servicio.","imageUrl":"http://www.dotechmx.com/assets/images/home-section/technologies.png"}
      ];
    this.highlightData = this.highlightData[0];
  }

  loadContent() {
    let obj = {
      table: "services"
    };
    

    this.connectionService
      .getService("getItem.php", obj)
      .subscribe((data) => {
      
        if (!data.error) {
          this.servicesItems = data.info;
          this.loadHighlight();
        }
      });
  }

  loadHighlight(){
    
    let obj = {
      idPosition: '1',
      table: "highlightData"
    };

    this.connectionService.getService("getItemData.php", obj).subscribe(data => {
      
      if (!data.error) {
        this.highlightData = data.info[0];
      }
    });
  }


}
