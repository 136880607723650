import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutUsComponent } from './about-us.component';
import { AboutUsContentModule } from 'src/app/components/about-us-content/about-us-content.module';
import { NavigationModule } from 'src/app/components/navigation/navigation.module';
import { ClientsModule } from 'src/app/components/clients/clients.module';


@NgModule({
  declarations: [AboutUsComponent],
  imports: [
    CommonModule,
    AboutUsContentModule,
    NavigationModule,
    ClientsModule
  ]
})
export class AboutUsModule { }
