import { Component, OnInit } from '@angular/core';
declare const Waypoint: any;

@Component({
  selector: 'app-methodology',
  templateUrl: './methodology.component.html',
  styleUrls: ['./methodology.component.scss']
})
export class MethodologyComponent implements OnInit {
  methodologyTitle: boolean = false;
  constructor() { }

  ngOnInit() {
    let self = this;
    let waypoint = new Waypoint({
      
      element: document.getElementById('methodologyTitle'),
      handler: function(direction) {
        self.methodologyTitle = true;
      },
      offset: '65%'
    });
  }

}
