import { Component, OnInit } from "@angular/core";
declare const Waypoint: any;

@Component({
  selector: "app-technologies-content",
  templateUrl: "./technologies.component.html",
  styleUrls: ["./technologies.component.scss"]
})
export class TechnologiesComponent implements OnInit {
  techTitle:boolean= false;
  technologiesItems: any = [
    {
      title: "Bases de Datos",
      img: "icon-database",
      items: [
        { description: "SQL Server" },
        { description: "Oracle" },
        { description: "Sybase" },
        { description: "DB2" },
        { description: "Access" },
        { description: "MySQL" },
        { description: "Postgres" }
      ]
    },
    {
      title: "Tecnologías Nube",
      img: "icon-cloud",
      items: [
        { description: "Web Apps" },
        { description: "App Service" },
        { description: "Logic APP" },
        { description: "Mobile Apps" },
        { description: "CodeOnTime" },
        { description: "Nativescript" },
        { description: "Rest APIs" }
      ]
    },
    {
      title: "Herramientas BI",
      img: "icon-bi",
      items: [
        { description: "Microstrategy" },
        { description: "Tableau" },
        { description: "Business Objects" },
        { description: "Cognos" },
        { description: "Artus" },
        { description: "Oracle BI" }
      ]
    },
    {
      title: "Programación",
      img: "icon-software",
      items: [
        { description: "Java" },
        { description: "VB 6.0" },
        { description: "VB .NET/C# .NET" },
        { description: "ASP/PHP" },
        { description: "Javascript/VB Script" },
        { description: "HTML/XML/XSL" }
      ]
    },
    {
      title: "Oracle Retail",
      img: "icon-oracle",
      items: [
        { description: "Oracle Forms" },
        { description: "Pro*C" },
        { description: "PL/SQL" },
        { description: "Oracle Apex" }
      ]
    },
    {
      title: "Herramientas Reporteo",
      img: "icon-report",
      items: [
        { description: "Oracle Publisher/ Reports" },
        { description: "Access" },
        { description: "Crystal Reports" },
        { description: "SQL Server Reporting Services" }
      ]
    },
    {
      title: "Calidad - QA",
      img: "icon-qa",
      items: [
        { description: "ALM-HP" },
        { description: "Atlassian Jira" },
        { description: "Mantis" },
        { description: "Enterprise Architect" },
        { description: "Selenium" },
        { description: "ISTQB" }
      ]
    },
    {
      title: "Orquestadores, Middleware e Integradores",
      img: "icon-middleware",
      items: [
        { description: "Automation" },
        { description: "UC4/Automic" },
        { description: "Control-m" },
        { description: "DevOps" },
        { description: "Oracle SOA" }
      ]
    },
    {
      title: "Herramientas ETL",
      img: "icon-etl",
      items: [
        { description: "Sagent" },
        { description: "Oracle Warehouse Builder" },
        { description: "Oracle Data Integrator" },
        { description: "Datastage" },
        { description: "Informatica PWC" },
        { description: "SQL Server Integration Services" }
      ]
    }
  ];
  constructor() {}

  ngOnInit() {
    let self = this;
    let waypoint = new Waypoint({
      
      element: document.getElementById('techTitle'),
      handler: function(direction) {
        self.techTitle = true;
      },
      offset: '65%'
    });
  }
}
