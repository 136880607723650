import { Component, OnInit } from '@angular/core';


declare const Waypoint: any;

@Component({
  selector: 'app-about-us-content',
  templateUrl: './about-us-content.component.html',
  styleUrls: ['./about-us-content.component.scss']
})
export class AboutUsContentComponent implements OnInit {
  public number: number = 1000;
  aboutTitle: boolean = false;
  aboutMision:boolean = false;

  constructor() { }

  ngOnInit() {
    let self = this;
    let waypoint = new Waypoint({
      
      element: document.getElementById('aboutTitle'),
      handler: function(direction) {
        self.aboutTitle = true;
      },
      offset: '65%'
    });
    let waypoint2 = new Waypoint({
      
      element: document.getElementById('aboutMision'),
      handler: function(direction) {
        self.aboutMision = true;
      },
      offset: '65%'
    })
  }

}
