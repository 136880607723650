import { Component, OnInit } from "@angular/core";
import { ConnectionService } from 'src/app/services/connection.service';

declare const Waypoint: any;

@Component({
  selector: 'app-contact-content',
  templateUrl: './contact-content.component.html',
  styleUrls: ['./contact-content.component.scss']
})
export class ContactContentComponent implements OnInit {
  contactTitle: boolean = false;
  locationsTitle: boolean = false;
  mty: boolean = false;
  tepic: boolean = false;
  cdmx: boolean = false;
  locations: any = [];
  locationsLength: any;



  constructor(private connectionService: ConnectionService) { }

  ngOnInit() {
    let self = this;
    let waypoint = new Waypoint({

      element: document.getElementById('contactTitle'),
      handler: function (direction) {
        self.contactTitle = true;
      },
      offset: '65%'
    });
    let waypoint2 = new Waypoint({

      element: document.getElementById('locationsTitle'),
      handler: function (direction) {
        self.locationsTitle = true;
      },
      offset: '65%'
    });

    this.loadContent();
    // this.loadFake();


  }

  loadFake() {
    this.locations = [
      { "id": "1", "city": "MONTERREY, NUEVO LEÓN", "building": "Torre Avalanz - Piso 17 y 18", "address": "Av. Batallón de San Patricio #109 Sur, Col. Valle Oriente, San Pedro Garza García, N.L.", "mapUrl": "https:\/\/goo.gl\/maps\/dj9nh1zdxu9Bz4zM8" },
      { "id": "2", "city": "TEPIC, NAYARIT", "building": "", "address": "San Luis #297 Norte, Col. Centro, Tepic, Nayarit.", "mapUrl": "https:\/\/goo.gl\/maps\/U262GxEtTPZp5HHeA" },
      { "id": "3", "city": "MÉXICO, D.F.", "building": "Plaza Carso", "address": "Calle Lago Zurich #219, Col. Ampliación Granada, Del. Miguel Hidalgo, México, D.F.", "mapUrl": "https:\/\/goo.gl\/maps\/WBhywTv5GBx5CSQGA" }
    ];

    this.locationsLength = this.locations.length;
    console.log("this.locationsLength", this.locationsLength);
  }

  loadContent() {

    let obj = {
      table: "locations"
    };
    

    this.connectionService
      .getService("getItem.php", obj)
      .subscribe((data) => {
        console.log("data getLocations is:", data);
        if (!data.error) {
          this.locations = data.info;
          this.locationsLength = this.locations.length;
        }
      });
  }

}
