import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './contact.component';
import { NavigationModule } from 'src/app/components/navigation/navigation.module';
import { ContactContentModule } from 'src/app/components/contact-content/contact-content.module';

@NgModule({
  declarations: [ContactComponent],
  imports: [
    CommonModule,
    NavigationModule,
    ContactContentModule
  ]
})
export class ContactModule { }
