import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutUsContentComponent } from './about-us-content.component';
import { StickRibbonModule } from '../stick-ribbon/stick-ribbon.module';

@NgModule({
  declarations: [AboutUsContentComponent],
  exports: [AboutUsContentComponent],
  imports: [
    CommonModule,
    StickRibbonModule
  ]
})
export class AboutUsContentModule { }
